import { RegionId } from "@sp-crm/core";
import { useAdvancedSearchQuery } from "components/advanced-search/advanced-search-request";
import { useEffect, useMemo, useState } from "react";
import { useCanSeeReferralSourceOwners } from "store/selectors/hooks";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    AdvancedSearchRequestType,
    BridgeEntityResult,
    FieldConditionOperator,
} from "../../../generated/graphql";

interface ReferralSearchQueries {
    referenceContact: AdvancedSearchRequest;
    referenceOrganization: AdvancedSearchRequest;
    community: AdvancedSearchRequest;
    communityContact: AdvancedSearchRequest;
}

interface ReferralSearchBadgeCounts {
    referenceContact: number;
    referenceOrganization: number;
    community: number;
    communityContact: number;
}

export const useReferralSearchQueries = (
    search: string,
    regionId: RegionId,
): ReferralSearchQueries => {
    const isReferralPreferenceEnabled = useCanSeeReferralSourceOwners();
    const initialSearchRequest: ReferralSearchQueries = useMemo(() => {
        return {
            communityContact: {
                entityType: AdvancedSearchEntityType.CommunityContact,
                type: AdvancedSearchRequestType.Implicit,
                select: [
                    { title: "Name", fieldName: "contact.name" },
                    {
                        title: "Community",
                        fieldName: "community.name",
                    },
                ].filter(s => !!s),
                sort: { column: "contact.name", ascending: true },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.Or,
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.RelationCondition,
                                    fieldName: "contact",
                                    children: [
                                        {
                                            nodeType:
                                                AdvancedSearchConditionNodeType.FieldCondition,
                                            fieldName: "name",
                                            operator:
                                                FieldConditionOperator.StringContains,
                                            textValue: search,
                                        },
                                    ],
                                },
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.RelationCondition,
                                    fieldName: "community",
                                    children: [
                                        {
                                            nodeType:
                                                AdvancedSearchConditionNodeType.FieldCondition,
                                            fieldName: "name",
                                            operator:
                                                FieldConditionOperator.StringContains,
                                            textValue: search,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                            fieldName: "community",
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "regionId",
                                    operator: FieldConditionOperator.StringEquals,
                                    textValue: regionId,
                                },
                            ],
                        },
                    ],
                },
            },
            community: {
                entityType: AdvancedSearchEntityType.Community,
                type: AdvancedSearchRequestType.Implicit,
                select: [{ fieldName: "name" }, { fieldName: "city" }],
                sort: { column: "name", ascending: true },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                            fieldName: "name",
                            operator: FieldConditionOperator.StringContains,
                            textValue: search,
                        },
                        {
                            nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                            operator: FieldConditionOperator.StringEquals,
                            fieldName: "regionId",
                            textValue: regionId,
                        },
                    ],
                },
            },
            referenceOrganization: {
                entityType: AdvancedSearchEntityType.ReferenceBusiness,
                type: AdvancedSearchRequestType.Implicit,
                select: [
                    {
                        title: "Name",
                        fieldName: "businessContactEntity.name",
                    },
                    isReferralPreferenceEnabled ? { fieldName: "ownerId" } : null,
                ].filter(s => !!s),
                sort: { column: "businessContactEntity.name", ascending: true },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.RelationCondition,
                            fieldName: "businessContactEntity",
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.FieldCondition,
                                    fieldName: "name",
                                    operator: FieldConditionOperator.StringContains,
                                    textValue: search,
                                },
                            ],
                        },
                        {
                            nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                            operator: FieldConditionOperator.StringEquals,
                            fieldName: "regionId",
                            textValue: regionId,
                        },
                    ],
                },
            },
            referenceContact: {
                entityType: AdvancedSearchEntityType.ReferenceContact,
                type: AdvancedSearchRequestType.Implicit,
                select: [
                    {
                        title: "Name",
                        fieldName: "contactEntity.name",
                    },
                    {
                        title: "Business",
                        fieldName: "business.businessContactEntity.name",
                    },
                    { fieldName: "community.name" },
                ].filter(s => !!s),
                sort: { column: "contactEntity.name", ascending: true },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.Or,
                            children: [
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.RelationCondition,
                                    fieldName: "contactEntity",
                                    children: [
                                        {
                                            nodeType:
                                                AdvancedSearchConditionNodeType.FieldCondition,
                                            fieldName: "name",
                                            operator:
                                                FieldConditionOperator.StringContains,
                                            textValue: search,
                                        },
                                    ],
                                },
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.RelationCondition,
                                    fieldName: "business",
                                    children: [
                                        {
                                            nodeType:
                                                AdvancedSearchConditionNodeType.RelationCondition,
                                            fieldName: "businessContactEntity",
                                            children: [
                                                {
                                                    nodeType:
                                                        AdvancedSearchConditionNodeType.FieldCondition,
                                                    fieldName: "name",
                                                    operator:
                                                        FieldConditionOperator.StringContains,
                                                    textValue: search,
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    nodeType:
                                        AdvancedSearchConditionNodeType.RelationCondition,
                                    fieldName: "community",
                                    children: [
                                        {
                                            nodeType:
                                                AdvancedSearchConditionNodeType.FieldCondition,
                                            fieldName: "name",
                                            operator:
                                                FieldConditionOperator.StringContains,
                                            textValue: search,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                            operator: FieldConditionOperator.StringEquals,
                            fieldName: "regionId",
                            textValue: regionId,
                        },
                    ],
                },
            },
        };
    }, [search, regionId, isReferralPreferenceEnabled]);
    return initialSearchRequest;
};

export const useReferralSearchBadgeCounts = (
    search: ReferralSearchQueries,
    entityMetadataList: BridgeEntityResult[],
): ReferralSearchBadgeCounts => {
    const [referenceContact, setReferenceContact] = useState(0);
    const [referenceOrganization, setReferenceOrganization] = useState(0);
    const [community, setCommunity] = useState(0);
    const [communityContact, setCommunityContact] = useState(0);

    const referenceContactResult = useAdvancedSearchQuery(
        search.referenceContact,
        entityMetadataList,
    );
    useEffect(() => {
        setReferenceContact(referenceContactResult.data?.advancedSearch.total ?? 0);
    }, [referenceContactResult.data?.advancedSearch.total]);

    const referenceOrganizationResult = useAdvancedSearchQuery(
        search.referenceOrganization,
        entityMetadataList,
    );
    useEffect(() => {
        setReferenceOrganization(
            referenceOrganizationResult.data?.advancedSearch.total ?? 0,
        );
    }, [referenceOrganizationResult.data?.advancedSearch.total]);

    const communityResult = useAdvancedSearchQuery(search.community, entityMetadataList);
    useEffect(() => {
        setCommunity(communityResult.data?.advancedSearch.total ?? 0);
    }, [communityResult.data?.advancedSearch.total]);

    const communityContactResult = useAdvancedSearchQuery(
        search.communityContact,
        entityMetadataList,
    );
    useEffect(() => {
        setCommunityContact(communityContactResult.data?.advancedSearch.total ?? 0);
    }, [communityContactResult.data?.advancedSearch.total]);

    const badgeCounts = useMemo(() => {
        return {
            referenceContact,
            referenceOrganization,
            community,
            communityContact,
        };
    }, [referenceContact, referenceOrganization, community, communityContact]);
    return badgeCounts;
};
