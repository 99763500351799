import { CommunityId } from "@sp-crm/core";
import {
    AdvancedSearchResults,
    RenderRowActionParams,
} from "components/advanced-search/advanced-search-results";
import { SearchInputDebounced } from "components/ui/search-input-debounced";
import { SecondaryButton } from "components/ui/secondary-button";
import { produce } from "immer";
import React, { useState } from "react";
import { useRegionId } from "store/selectors/hooks";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    AdvancedSearchRequestType,
    FieldConditionOperator,
} from "../../../generated/graphql";

interface ReferenceCommunityPickerProps {
    onSelect: (comunityId: CommunityId) => void;
}

export const ReferenceCommunityPicker: React.FC<
    ReferenceCommunityPickerProps
> = props => {
    const { onSelect } = props;
    const regionId = useRegionId();

    const initialRequest: AdvancedSearchRequest = {
        entityType: AdvancedSearchEntityType.Community,
        type: AdvancedSearchRequestType.Implicit,
        select: [{ fieldName: "name" }, { fieldName: "city" }],
        sort: { column: "name", ascending: true },
        condition: {
            nodeType: AdvancedSearchConditionNodeType.And,
            children: [
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    fieldName: "name",
                    operator: FieldConditionOperator.StringContains,
                    textValue: "",
                },
                {
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.StringEquals,
                    fieldName: "regionId",
                    textValue: regionId,
                },
            ],
        },
    };

    const [searchRequest, setSearchRequest] =
        useState<AdvancedSearchRequest>(initialRequest);

    const handleSearchInput = React.useCallback(
        (value: string) => {
            setSearchRequest(prev =>
                produce(prev, draft => {
                    draft.condition.children[0].textValue = value;
                }),
            );
        },
        [setSearchRequest],
    );

    const renderRowAction = React.useCallback(
        (params: RenderRowActionParams) => {
            return (
                <SecondaryButton
                    className="text-sm"
                    onClick={e => {
                        e.preventDefault();
                        onSelect(params.record.id as CommunityId);
                    }}>
                    Select
                </SecondaryButton>
            );
        },
        [onSelect],
    );

    return (
        <div>
            <SearchInputDebounced
                initial=""
                onChange={handleSearchInput}
                placeholder="Search for community"
                autoFocus
            />
            <AdvancedSearchResults
                request={searchRequest}
                onChangeRequest={setSearchRequest}
                renderRowAction={renderRowAction}
            />
        </div>
    );
};
