import { CalendarDate, getYearsSinceAsString } from "@sp-crm/core";
import { AutosavingInput } from "components/ui/autosaving-input";
import { CalendarDateInput } from "components/ui/calendar-date";
import { defaultLinkStyle } from "components/ui/link";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { usePreferences } from "store/selectors/hooks";

interface DateOfBirthProps {
    birthday: CalendarDate;
    isBirthdayFake?: boolean;
    additionalAgeBirthdayLabel?: string;
    updateAge: (ageStr: string) => void;
    updateBirthday: (birthday: string) => void;
}

type View = "age" | "birthdate";
interface SwitchView {
    switchView: (target: View) => void;
}

export const DateOfBirth: React.FC<DateOfBirthProps> = props => {
    const { preferAge } = usePreferences();
    const { isBirthdayFake, birthday } = props;
    const [view, setView] = useState<View>(
        birthday
            ? isBirthdayFake
                ? "age"
                : "birthdate"
            : preferAge
            ? "age"
            : "birthdate",
    );
    if (view === "age") {
        return <AgeActual {...props} switchView={setView} />;
    } else {
        return <DateOfBirthActual {...props} switchView={setView} />;
    }
};

const DateOfBirthActual: React.FC<DateOfBirthProps & SwitchView> = props => {
    const { birthday, updateBirthday, switchView } = props;
    const switchViewCallback = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            switchView("age");
        },
        [switchView],
    );
    return (
        <div className="flex items-end space-x-2 lg:space-x-4">
            <div>
                <CalendarDateInput
                    initial={birthday}
                    label="Date of birth"
                    onCommit={calendarDate => {
                        updateBirthday(calendarDate?.toString() || null);
                    }}
                />
            </div>
            {birthday ? (
                <div className="mb-2">Age: {getYearsSinceAsString(birthday)}</div>
            ) : null}
            <div className="mb-2">
                <a href="#" className={defaultLinkStyle} onClick={switchViewCallback}>
                    Use Age instead
                </a>
            </div>
        </div>
    );
};

const AgeActual: React.FC<DateOfBirthProps & SwitchView> = props => {
    const { birthday, updateAge, switchView, isBirthdayFake } = props;
    const switchViewCallback = useCallback(
        (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            switchView("birthdate");
        },
        [switchView],
    );
    const initialAgeValue = birthday ? getYearsSinceAsString(birthday) : "";
    return (
        <div className="flex items-end space-x-2 lg:space-x-4">
            <div>
                <AutosavingInput
                    initial={initialAgeValue}
                    label="Age"
                    onCommit={updateAge}
                />
            </div>
            {birthday ? (
                isBirthdayFake ? (
                    <div className="mb-2">
                        Born in approx. {moment(birthday).format("YYYY")}
                    </div>
                ) : (
                    <div className="mb-2">Born in {moment(birthday).format("YYYY")}</div>
                )
            ) : null}
            <div className="mb-2">
                <a href="#" className={defaultLinkStyle} onClick={switchViewCallback}>
                    Use Date of Birth instead
                </a>
            </div>
        </div>
    );
};
