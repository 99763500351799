import { ActionType } from "@sp-crm/core";
import { NavMenu } from "components/navigation/nav-menu";
import React from "react";
import { useIsAllowed } from "store/selectors/hooks";

export const ReportsNavigation: React.FC<unknown> = () => {
    const legacyReportsEnabled = useIsAllowed(ActionType.ViewReports);
    const viewAllEnabled = useIsAllowed(ActionType.ViewAllInvoices);
    const viewOwnEnabled = useIsAllowed(ActionType.ViewOwnInvoices);
    const viewCustomReportsEnabled = useIsAllowed(
        ActionType.AdvancedSearchExplicitExecute,
    );

    const links = [];
    if (legacyReportsEnabled) {
        links.push({
            exact: true,
            to: "/reports",
            label: "Overview",
        });
    }
    if (legacyReportsEnabled && (viewAllEnabled || viewOwnEnabled)) {
        links.push({
            to: "/reports/show/revenue",
            label: "Client Placement",
        });
    }
    if (legacyReportsEnabled) {
        links.push({
            to: "/reports/show/referrals",
            label: "Referrals",
        });
    }

    if (viewCustomReportsEnabled) {
        links.push({
            to: "/reports/show/custom",
            label: "Custom Reports",
        });
    }

    return <NavMenu links={links} />;
};
