import { LatLng } from "@sp-crm/core";
import { HoveredMapEntity } from "components/community-search/community-map/types";
import { Icon } from "components/icon";
import { ClampedText } from "components/ui/clamped-text";
import { defaultLinkStyle } from "components/ui/link";
import { SearchUserProfilesQuery } from "generated/graphql";
import React, { useCallback } from "react";
import { PhoneSingleNumber } from "util/phone-display";
import { breakNewlines } from "util/text";
import { AgentDirectoryThumbnail } from "./agent-directory-thumbnail";

interface AgentDirectorySearchListProps {
    agents: SearchUserProfilesQuery["searchUserProfiles"]["hits"];
    onHover: (agent: HoveredMapEntity | null) => void;
}

export const AgentDirectorySearchList: React.FC<
    AgentDirectorySearchListProps
> = props => {
    const { agents: agentHits, onHover } = props;

    const handleHover = useCallback(
        (agent: HoveredMapEntity) => {
            onHover(agent);
        },
        [onHover],
    );

    const handleEndHover = useCallback(() => {
        onHover(null);
    }, [onHover]);

    return (
        <ul className="space-y-4">
            {agentHits.map(agent => (
                <li key={agent.id}>
                    <AgentDirectorySearchCard
                        agent={agent}
                        onHover={handleHover}
                        onEndHover={handleEndHover}
                    />
                </li>
            ))}
        </ul>
    );
};

interface AgentDirectorySearchCardProps {
    agent: SearchUserProfilesQuery["searchUserProfiles"]["hits"][number];
    onHover: (agent: HoveredMapEntity) => void;
    onEndHover: () => void;
}

const AgentDirectorySearchCard: React.FC<AgentDirectorySearchCardProps> = props => {
    const { agent, onHover, onEndHover } = props;

    const handleMouseEnter = () => {
        let location: LatLng | null = null;
        if (agent.latitude && agent.longitude) {
            location = {
                lat: parseFloat(agent.latitude),
                lng: parseFloat(agent.longitude),
            };
        }
        onHover({
            entityId: agent.id,
            location,
        });
    };

    const handleMouseLeave = () => {
        onEndHover();
    };

    return (
        <div
            className="col-span-1 divide-y divide-gray-200 rounded md:rounded-lg bg-white shadow p-6"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="flex space-x-6 w-full items-start justify-between p-6">
                <div>
                    <AgentDirectoryThumbnail agent={agent} />
                </div>
                <div className="flex-1 overflow-auto">
                    <div className="flex space-x-2">
                        <div className="flex-1">
                            <h3 className="twoverride text-gray-900 text-lg">
                                <div className="flex space-x-2 items-center">
                                    <div className="w-6">
                                        <Icon name="40_ReferralPerson" />
                                    </div>
                                    <div>{agent.name || "(no name)"}</div>
                                </div>
                            </h3>
                            <div className="text-sm text-gray-500">
                                {agent.businessName ? (
                                    <div>{agent.businessName}</div>
                                ) : null}
                                {agent.territories?.length > 0 ? (
                                    <ul>
                                        {agent.territories.map((territory, i) => (
                                            <li key={i}>
                                                {territory.city}, {territory.state}{" "}
                                                {territory.zip}
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}
                                {agent.phone ? (
                                    <PhoneSingleNumber phoneNumber={agent.phone} />
                                ) : null}
                                {agent.email ? (
                                    <a
                                        href={`mailto:${agent.email}`}
                                        className={defaultLinkStyle}>
                                        {agent.email}
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 border-t border-gray-200 pt-2">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                            {agent.summary ? (
                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Summary
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <ClampedText lines={3}>
                                            {breakNewlines(agent.summary)}
                                        </ClampedText>
                                    </dd>
                                </div>
                            ) : null}
                            {agent.approach ? (
                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Operational Approach
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <ClampedText lines={3}>
                                            {breakNewlines(agent.approach)}
                                        </ClampedText>
                                    </dd>
                                </div>
                            ) : null}
                            {agent.certifications?.length > 0 ? (
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Certifications
                                    </dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <ul>
                                            {agent.certifications.map((cert, i) => (
                                                <li key={i}>{cert}</li>
                                            ))}
                                        </ul>
                                    </dd>
                                </div>
                            ) : null}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    );
};
