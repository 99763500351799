import { ContentFull, ContentHeader, ContentSubheader } from "components/layout";
import { PhoneInput } from "components/shared/phone-input";
import { AutosavingCheckbox } from "components/ui/autosaving-checkbox";
import { AutosavingInput } from "components/ui/autosaving-input";
import { InlineBanner } from "components/ui/inline-banner";
import { ReorderableStringList } from "components/ui/reorderable-string-list";
import {
    PatchUserProfileRequest,
    useGetUserProfileQuery,
    usePatchUserProfileMutation,
    UserProfileStatus,
} from "generated/graphql";
import React, { useCallback, useMemo } from "react";
import { useProductName } from "store/selectors/branding";
import { useFeature } from "store/selectors/hooks";
import { ProfilePicture } from "./profile-picture";
import { UserTerritorySettings } from "./user-territory-settings";

export const UserProfileSettings: React.FC<unknown> = _ => {
    const isEnabled = useFeature("publicProfile");

    const productName = useProductName();

    const userProfile = useGetUserProfileQuery({}, { keepPreviousData: true });
    const patchUserProfile = usePatchUserProfileMutation();

    const doUpdate = useCallback(
        async (params: PatchUserProfileRequest) => {
            await patchUserProfile.mutateAsync({
                params,
            });
            userProfile.refetch();
        },
        [patchUserProfile, userProfile],
    );

    const handleShowInDirectoryChange = useCallback(
        async (v: boolean) => {
            await doUpdate({
                status: v ? UserProfileStatus.Public : UserProfileStatus.Private,
            });
        },
        [doUpdate],
    );

    const handleNameChange = useCallback(
        async (v: string) => {
            await doUpdate({
                name: v,
            });
        },
        [doUpdate],
    );

    const handleBusinessNameChange = useCallback(
        async (v: string) => {
            await doUpdate({
                businessName: v,
            });
        },
        [doUpdate],
    );

    const handleEmailChange = useCallback(
        async (v: string) => {
            await doUpdate({
                email: v,
            });
        },
        [doUpdate],
    );

    const handlePhoneChange = useCallback(
        async (v: string) => {
            await doUpdate({
                phone: v,
            });
        },
        [doUpdate],
    );

    const handleSummaryChange = useCallback(
        async (v: string) => {
            await doUpdate({ summary: v });
        },
        [doUpdate],
    );

    const handleApproachChange = useCallback(
        async (v: string) => {
            await doUpdate({ approach: v });
        },
        [doUpdate],
    );

    const handleCertificationsChange = useCallback(
        async (v: string[]) => {
            await doUpdate({ certifications: v });
        },
        [doUpdate],
    );

    const infoMessage = useMemo(() => {
        if (!userProfile.data) {
            return "none";
        }

        if (userProfile.data.getUserProfile.status === UserProfileStatus.Private) {
            return "private";
        }

        if (!userProfile.data.getUserProfile.isComplete) {
            return "incomplete";
        }

        return "complete";
    }, [userProfile.data]);

    if (!isEnabled || !userProfile.data) {
        return null;
    }

    return (
        <ContentFull>
            <ContentHeader>Public profile</ContentHeader>
            <ContentSubheader>
                Configure your public profile so that other users in the {productName}{" "}
                network can find you.
            </ContentSubheader>
            <div className="space-y-4">
                <div>
                    <AutosavingCheckbox
                        label={`Share my information below in the ${productName} Advisor Directory`}
                        initial={
                            userProfile.data.getUserProfile.status ===
                            UserProfileStatus.Public
                        }
                        onCommit={handleShowInDirectoryChange}
                    />
                    <div className="ml-6 text-gray-500 group-hover:text-gray-900 text-sm pr-20">
                        By checking this box, I acknowledge and agree that the information
                        provided in my profile (including my name, photo, and contact
                        details) will be visible to other Senior Place users in the
                        Advisor Directory. I understand that I may remove my profile from
                        the Advisor Directory at any time by unchecking this box.
                    </div>
                </div>
                {infoMessage === "complete" ? (
                    <InlineBanner type="success">
                        Your profile is complete and will appear in the {productName}{" "}
                        Advisor Directory.
                    </InlineBanner>
                ) : null}
                {infoMessage === "incomplete" ? (
                    <InlineBanner type="info">
                        Your profile is incomplete and will not appear in the{" "}
                        {productName} Advisor Directory. Please complete your name,
                        contact information, and service area to make your profile
                        visible.
                    </InlineBanner>
                ) : null}
                {infoMessage === "private" ? (
                    <InlineBanner type="info">
                        Your profile is currently private and will not appear in the{" "}
                        {productName} Advisor Directory. To view the Advisor Directory,
                        share your profile.
                    </InlineBanner>
                ) : null}
                <ProfilePicture
                    userProfile={userProfile.data.getUserProfile}
                    reload={userProfile.refetch}
                />
                <AutosavingInput
                    label="Name"
                    initial={userProfile.data.getUserProfile.name}
                    onCommit={handleNameChange}
                />
                <AutosavingInput
                    label="Business name"
                    initial={userProfile.data.getUserProfile.businessName}
                    onCommit={handleBusinessNameChange}
                />
                <AutosavingInput
                    label="Email"
                    initial={userProfile.data.getUserProfile.email}
                    onCommit={handleEmailChange}
                />
                <PhoneInput
                    label="Phone"
                    initial={userProfile.data.getUserProfile.phone}
                    onCommit={handlePhoneChange}
                />
                <AutosavingInput
                    label="Summary"
                    multiLine
                    initial={userProfile.data.getUserProfile.summary}
                    onCommit={handleSummaryChange}
                    maxLength={300}
                    placeholder="In 2-3 sentences Describe your professional background and expertise"
                />
                <AutosavingInput
                    label="Operational Approach"
                    multiLine
                    initial={userProfile.data.getUserProfile.approach}
                    onCommit={handleApproachChange}
                    placeholder="In 3-4 sentences, describe your approach to working with clients"
                    maxLength={500}
                />
                <ReorderableStringList
                    label="Certifications"
                    addButtonLabel="Add certification"
                    initial={userProfile.data.getUserProfile.certifications}
                    onCommit={handleCertificationsChange}
                />
                <UserTerritorySettings
                    territories={userProfile.data.getUserProfile.territories}
                    refetch={userProfile.refetch}
                />
            </div>
        </ContentFull>
    );
};
